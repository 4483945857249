import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Web-Barrierefreiheit ist ein ethisches Gebot und wirtschaftlich sinnvoll. Ihr unterstützt die Inklusion von Menschen
mit Behinderung und erreicht damit potenziell mehr Kund:innen. Dennoch, als Web-Entwickler:in denkt ihr euch
vielleicht: `}<ItalicText mdxType="ItalicText">{`„Das ist super für meine Firma und die ganze Gesellschaft. Aber für mich heißt es noch
mehr Arbeit!“`}</ItalicText></p>
    <p>{`Ich kann euch verstehen! Das Thema Barrierefreiheit kann am Anfang ganz schön überwältigen. Und ich gebe zu, manche
Sachen wie `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions"
      }}>{`ARIA Live-Regionen`}</a>{`
bereiten tatsächlich etwas mehr Arbeit. Doch ich verrate euch ein kleines Geheimnis: Barrierefreier Code wird euer
Leben als Web-Entwickler:in einfacher machen und euch langfristig viel Zeit sparen!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAF3teXDHCv/xAAaEAACAwEBAAAAAAAAAAAAAAABAgAREgMT/9oACAEBAAEFAmi9dSwYwIGaVk8z/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEREv/aAAgBAwEBPwGmpf/EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPwGpj//EABsQAQACAgMAAAAAAAAAAAAAAAEAAhJhEDJx/9oACAEBAAY/Asglqp4hFzrXU7b4xJ//xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMUFRYdH/2gAIAQEAAT8hTjbfHUuJU4xsGaVg7WuWcxHwhqtnsJ//2gAMAwEAAgADAAAAECsv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAEDAQE/EDUg3//EABYRAQEBAAAAAAAAAAAAAAAAAAEAIf/aAAgBAgEBPxBxDF//xAAcEAADAQADAQEAAAAAAAAAAAABESEAMUFRgaH/2gAIAQEAAT8QI7pyTBR4xQzgwCcfnGdmHJNz80IwLFVeYrSXYYNw94XAFZxvzf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Web-Entwicklerin sitzt am Arbeitsplatz mit drei Monitoren. Auf einem Bildschirm sind Code-Zeilen zu sehen.",
          "title": "Eine Web-Entwicklerin sitzt am Arbeitsplatz mit drei Monitoren. Auf einem Bildschirm sind Code-Zeilen zu sehen.",
          "src": "/static/b6f50ec986167ffd580a454bdc89babf/e5166/pexels-thisisengineering-web-dev-workplace.jpg",
          "srcSet": ["/static/b6f50ec986167ffd580a454bdc89babf/f93b5/pexels-thisisengineering-web-dev-workplace.jpg 300w", "/static/b6f50ec986167ffd580a454bdc89babf/b4294/pexels-thisisengineering-web-dev-workplace.jpg 600w", "/static/b6f50ec986167ffd580a454bdc89babf/e5166/pexels-thisisengineering-web-dev-workplace.jpg 1200w", "/static/b6f50ec986167ffd580a454bdc89babf/b17f8/pexels-thisisengineering-web-dev-workplace.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © ThisIsEngineering / pexels.com`}</em></p>
    <p>{`Ihr glaubt mir nicht? Dann lest einfach die folgenden drei Gründe, warum mit Barrierefreiheit alles besser ist.`}</p>
    <h2>{`1. Semantisches Markup ist leicht lesbar und wartbar`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.w3schools.com/html/html5_semantic_elements.asp"
      }}>{`Semantisches Markup`}</a>{` vermittelt Informationen über die
Bedeutung von Elementen nicht nur an assistive Technologien. Es vermittelt diese Bedeutung auch den Entwickler:innen, die
den Code lesen, bearbeiten und umgestalten.`}</p>
    <p>{`Stellt euch vor, ihr seid neu im Team und wollt euch in die Code-Basis einlesen. Würdet ihr vor Freude Luftsprünge machen,
wenn ihr solchen Code vorfindet?`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div class="fixed-header" role="banner">
    <div id="header-logo" role="img" aria-label="Logo Example Page"></div>
    <div role="navigation" aria-label="Main Navigation">
        <div role="list">
            <div role="listitem"><a href="/">Home</a></div>
            <div role="listitem"><a href="/about">About</a></div>
        </div>
    </div>
</div>
<div role="main">
    <div role="heading" aria-level="1">Best Pizza in Town</div>
    <div role="heading" aria-level="2">Pizza Salami</div>
    <div id="pizza-salami" role="img" aria-label="Pizza with salami slices"></div>
    <div>Some text</div>
    <div role="heading" aria-level="2">Pizza Capricciosa</div>
    <div id="pizza-capri" role="img" aria-label="Pizza with baked ham, mushroom and artichoke"></div>
    <div>Some text</div>
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Puh, das sind schon verdammt viele `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` Tags! Irgendwie schwer zu verstehen. Tja, dann
würdet ihr euch über folgenden Code wohl mehr freuen:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<header>
    <img src="/header-logo.svg" alt="Logo Example Page">
    <nav aria-label="Main Navigation">
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
        </ul>
    </nav>
</header>
<main>
    <h1>Best Pizza in Town</h1>
    <h2>Pizza Salami</h2>
    <img src="/pizza-salami.jpg" alt="Pizza with salami slices">
    <p>Some text</p>
    <h2>Pizza Capricciosa</h2>
    <img src="/pizza-capri.jpg" alt="Pizza with baked ham, mushroom and artichoke">
    <p>Some text</p>
</main>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ah, das ist besser! Auf dieser Basis könnt ihr euch im Team sinnvoll über den HTML-Code austauschen. Notiz am Rande:
Beide Code-Beispiele bereiten den Inhalt barrierefrei für Screenreader-Nutzer:innen auf. Aber nur das zweite Beispiel
ist vorteilhaft für Entwickler:innen.`}</p>
    <p>{`Wenn ihr jetzt denkt: `}<ItalicText mdxType="ItalicText">{`„Boah, der übertreibt ja völlig mit dieser Spaghetti-Code-Hölle!“`}</ItalicText>{` Dann
kann ich nur antworten, dass ich Variationen davon schon auf unzähligen Websites gesehen habe. Einfach nur traurig.`}</p>
    <h2>{`2. Native Bedienelemente sind standardmäßig tastaturbedienbar`}</h2>
    <p>{`HTML-Elemente wie `}<InlineCode mdxType="InlineCode">{`a`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`input type="checkbox"`}</InlineCode>{`
oder `}<InlineCode mdxType="InlineCode">{`input type="radio"`}</InlineCode>{` sind alle von Haus aus barrierefrei. Zum Beispiel können Nutzer:innen mit
der Tab-Taste von Schaltfläche zu Schaltfläche springen und diese mit der Enter- oder Leertaste aktivieren.`}</p>
    <p>{`Stellt euch vor, ihr wollt eine Schaltfläche mit dem `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` Element implementieren – warum auch immer.
Damit dieses Monstrum mit der Tastatur bedienbar wird, müsstet ihr folgende Schritte unternehmen:`}</p>
    <ul>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">tabindex="0"</InlineCode> setzen, um das Div in die Tab-Reihenfolge aufzunehmen.
      </li>
      <li parentName="ul">{`Einen gut sichtbaren Fokus-Indikator setzen, der vermittelt wann das Div den Fokus hat.`}</li>
      <li parentName="ul">{`Mit einem `}<InlineCode mdxType="InlineCode">{`keydown`}</InlineCode>{` Event-Handler die Aktivierung des „Buttons“ mit Enter- und Leertaste ermöglichen.`}</li>
    </ul>
    <p>{`Das Folgende ist zu tun, wenn ihr stattdessen das native `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` Element verwendet:`}</p>
    <ul>
      <li parentName="ul">{`Nichts!`}</li>
    </ul>
    <p>{`Buttons sind standardmäßig tastaturbedienbar und lösen automatisch das `}<InlineCode mdxType="InlineCode">{`click`}</InlineCode>{` Event aus, wenn
die Enter- oder Leertaste gedrückt wird. Weiters könnt ihr das Styling sehr einfach mit CSS anpassen. Das trifft auch auf
die meisten Formularelemente zu. Lest dazu meinen `}<a parentName="p" {...{
        "href": "/de/styling-accessible-web-forms.de/"
      }}>{`Artikel über Web-Formulare`}</a>{`.`}</p>
    <h2>{`3. Zustandsbezogene, semantische CSS-Selektoren sind aussagekräftiger und robuster`}</h2>
    <p>{`Ihr könnt die semantischen Tag-Namen und `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
      }}>{`ARIA-Attribute`}</a>{`
für aussagekräftige und leicht verständliche CSS-Selektoren nutzen. Zum
Beispiel: `}<InlineCode mdxType="InlineCode">{`header nav > ul { ... }`}</InlineCode>{` oder `}<InlineCode mdxType="InlineCode">{`button`}{`[`}{`aria-expanded="true"	`}{`]`}{` { ... }`}</InlineCode>{`.`}</p>
    <p>{`Indem die CSS-Regeln alleine auf HTML-Tags und -Attributen aufbauen, braucht ihr keine zusätzlichen CSS-Klassen, die
dynamisch mit JavaScript gesetzt werden. Stattdessen könnt ihr euch darauf konzentrieren, die Geschäftslogik alleine
im HTML-Code abzubilden (etwa indem `}<InlineCode mdxType="InlineCode">{`aria-expanded`}</InlineCode>{` auf „true“ oder „false“ gesetzt wird).`}</p>
    <p>{`Weitere Beispiele und eine ausführliche Auseinandersetzung mit dem Konzept bietet der
Artikel `}<a parentName="p" {...{
        "href": "https://benmyers.dev/blog/semantic-selectors/"
      }}>{`„Style with Stateful, Semantic Selectors“`}</a>{` von Ben Myers.`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3schools.com/html/html5_semantic_elements.asp"
        }}>{`Semantische HTML-Elemente (W3 Schools)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Learn/Accessibility/HTML"
        }}>{`HTML: Eine gute Basis für Barrierefreiheit (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors"
        }}>{`CSS Attribut-Selektoren (MDN)`}</a></li>
    </ul>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update am 16.12.2022</PostUpdateTitle>
    <p>{`Abschnitt über zustandsbezogene, semantische CSS-Selektoren wurde überarbeitet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      